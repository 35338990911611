import React from 'react'
import { PropTypes } from 'prop-types'

import { sanitizeMarkdown, ConditionalProductLink } from '../../index'
import { useMediaResize, useTranslation } from '../../../utils/providers'

function Breadcrumbs({ items = [] }) {
  const { t } = useTranslation()
  const { breakpoints } = useMediaResize()

  const isMobile = breakpoints.downMD

  let itemsToShow = [
    { text: t('BREADCRUMBS_ROOT_ITEM_LABEL'), link: '/' },
    ...items,
  ]

  if (isMobile && items.length > 1) {
    itemsToShow = [{ text: '...' }, ...itemsToShow.slice(-2)]
  }

  return (
    <nav aria-label={t('BREADCRUMBS_NAV_LABEL')} className="container">
      <ol className="breadcrumb d-flex flex-wrap">
        {itemsToShow?.map((item, index) => {
          const text = sanitizeMarkdown(item.text)
          return (
            <li key={index} className="breadcrumb-item">
              <ConditionalProductLink href={item.link} fallbackElement="span">
                {text}
              </ConditionalProductLink>
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      link: PropTypes.string,
    })
  ),
}

export default Breadcrumbs
